<template>
  <div class="landingPage container-xl mt-3">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="card rounded-lg">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <b-button size="sm" class="mb-3" @click="showCreateForm = true">Create New Letter +</b-button>
            </div>
            <div v-if="letters.length" class="tablerounededCorner">
              <table class="rounded-3 table table-striped roundedTable">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th style="width: 200px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="letter in letters" :key="letter.id">
                  <td>{{letter.id}}</td>
                  <td>{{letter.front_date}}</td>
                  <td>{{letter.name}}</td>
                  <td style="width: 200px;">
                    <router-link :to="{ name: 'agency.letter-template', params: { id: letter.id } }" class="btn btn-sm btn-success">Edit</router-link>
<!--                    <b-button
                        variant="link"
                        title="Delete"
                        size="lg"
                        class="btn-delete text-danger py-0"
                        :disabled="loadingRemove === letter.id"
                        @click="remove(letter)"
                    >
                      <b-spinner v-if="loadingRemove === letter.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>-->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="text-center">
              <b-spinner v-if="isBusy" />
              <span v-else>Create a new Letter Template to get started!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showCreateForm" :hide-footer="true" size="sm" title="New Letter Template">
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="letterForm"
      >
        <form
            class="letter-form d-flex flex-column"
            @submit.prevent="handleSubmit(createTemplate)"
        >
          <TextInput v-model="newLetterName" name="name" label="Template Name" rules="required" />
          <b-form-group>
            <b-button
                variant="success"
                :disabled="isBusy || (invalid && touched)"
                type="submit"
                class="w-100"
            >
              <b-spinner v-if="isBusy" small />
              <span v-else>Submit</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Letter Template">
      <p v-if="selectedLetter"
      >Are you sure you want to delete letter "{{
          selectedLetter.name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data() {
    return {
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      },
      page:1,
      total:0,
      showCreateForm:false,
      newLetterName:null,
      isBusy:false,
      apiLoaded:false,
      letters:[],
      loadingRemove:null,
      showDeleteModal:false,
      selectedLetter:null
    }
  },
  computed: {
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.getLetters();
  },
  methods: {
    createTemplate(){
      this.isBusy = true;
      this.$store
          .dispatch('leads/createNewLetter', {
            agency_id:this.user.agency.id,
            name:this.newLetterName
          })
          .then((resp) => {
            this.isBusy = false;
            // resp.data.id
            // Redirect to Edit Page
            this.$router.push({name:'agency.letter-template',params:{id:resp.data.id}});
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.letterForm.setErrors(err.response.data.errors)
            }
            this.isBusy = false
          })
    },
    getLetters(){
      this.isBusy = true;
      this.$store
          .dispatch('leads/getLetters',this.user.agency.id)
          .then((resp) => {
            this.total = resp.total;
            this.letters = resp.data;
            // resp.data.forEach(search => this.searches.push(search));
            this.page += 1;
            this.isBusy = false;
          })
          .catch((err) => {
            console.log(err);
            this.isBusy = false;
          })
    },
    remove(letter) {
      this.showDeleteModal = true;
      this.selectedLetter = letter;
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedLetter.id
      this.$store
          .dispatch('leads/deleteLetter', this.selectedLetter.id)
          .then(() => {
            this.getLetters();
          })
          .catch(() => {
            this.loadingRemove = null
          })
    },
  },
}
</script>
<style lang="scss">
.tablerounededCorner {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.roundedTable thead tr:last-child th{
  border-bottom: 1px solid #ccc;
}
.roundedTable {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.roundedTable th{
  padding: .6em;
}

.roundedTable td {
  padding: .4em;
}

.roundedTable th {
  text-align: left;
}

.roundedTable tr:last-child td {
  border-bottom: none;
}
.roundedTable.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eceff6;
}

</style>
